import { useEffect, useState } from "react";
import ReviewCard from "./review-card/ReviewCard";
import EditReviewDialog from "../EditReviewDialog";
import NullReview from "../../assets/png/NullReview.png";
import RoomEditReviewDialog from "../RoomEditReviewDialog";
import { toast } from "react-toastify";

const RoomReviewSection = (activeReview) => {
  const [reviews, setReviews] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [selectedReview, setSelectedReview] = useState(null);
  const [updateTrigger, setUpdateTrigger] = useState(0);

  const getFileUrl = `${window.env_url}/v1/files/`;

  useEffect(() => {
    fetchMyReviews();
  }, [updateTrigger]);

  const fetchMyReviews = async () => {
    const apiUrl = `${window.env_url}/v1/reviews/myreviews`;
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
      }
      const data = await response.json();
      const meetingResponse = await fetch(`${window.env_url}/v1/rooms`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const meetingData = await meetingResponse.json();

      for (let i = 0; i < meetingData.body[0].length; i++) {
        const roomId = meetingData.body[0][i].id;
        const roomName = meetingData.body[0][i].title.split("|")[0];
        for (let k = 0; k < data.body[0].length; k++) {
          if (data.body[0][k].roomId === roomId) {
            data.body[0][k].roomName = roomName;
          }
        }
      }

      console.log("meetingrooms", data.body);

      const sortedReviews = data.body[0].sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );

      setReviews(sortedReviews);
    } catch (error) {
      console.error(error.message);
    }
  };

  const handleReviewUpdated = () => {
    setUpdateTrigger((prevTrigger) => prevTrigger + 1);
  };

  const handleEdit = (review) => {
    setSelectedReview(review);
    setOpenEditDialog(true);
    //console.log("Edit clicked");
  };

  const handleDelete = async (review) => {
    const apiUrl = `${window.env_url}/v1/reviews/${review}`;
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(apiUrl, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();
        toast.success(`Review has been ${data.body}`);
        setUpdateTrigger((prevTrigger) => prevTrigger + 1);
      }
    } catch (error) {
      console.error(error.message);
    }
  };


  return (
    <>
      {reviews.length > 0 ? (
        <div className="rev-root-container">
          {reviews.map((review, index) => {
            const { user } = review;
            let reviewerImage;
            if (user?.image.includes(".io")) {
              reviewerImage = user?.image;
            } else {
              reviewerImage = getFileUrl + user?.image;
            }
            return (
              <ReviewCard
                key={index}
                reviewerName={user?.firstname}
                reviewerDate={review.createdAt}
                reviewerImage={reviewerImage}
                rating={review.rating}
                reviewText={review.description}
                name={review.roomName}
                onEdit={() => handleEdit(review)}
                onDelete={() => handleDelete(review.id)} // Pass review.id to handleDelete
                onClick={() => {}}
                type={"Room"}
              />
            );
          })}
        </div>
      ) : (
        <div className="nullReviewContainer">
          <img src={NullReview} id="nullReviewImg" alt="Null Review" />
          <p className="nullReviewMsg">You haven't reviewed anything yet!</p>
        </div>
      )}

      {openEditDialog && (
        <RoomEditReviewDialog
          open={openEditDialog}
          close={() => setOpenEditDialog(false)}
          review={selectedReview}
          onReviewUpdated={handleReviewUpdated}
          activeReview={activeReview}
        />
      )}
    </>
  );
};

export default RoomReviewSection;
