// Sidebar.jsx

import { ReactComponent as HomeIcon } from "../../assets/svg/HomeIcon.svg";
import { ReactComponent as MeetingIcon } from "../../assets/svg/MeetingIcon.svg";
import { ReactComponent as CoachesIcon } from "../../assets/svg/Coaches.svg";
import { ReactComponent as EventIcon } from "../../assets/svg/event_icon_white.svg"; // Ensure this SVG accepts fill
import { ReactComponent as SurveyIcon } from "../../assets/svg/Survey.svg";
import { ReactComponent as PolicyIcon } from "../../assets/svg/policyactive.svg"; // Ensure this SVG accepts fill
import "./Sidebar.css";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import ChatApp from "../../ChatApp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-solid-svg-icons";

const Sidebar = () => {
  const location = useLocation(); // Hook to get the current location
  const [activeLink, setActiveLink] = useState("");

  // Retrieve user data from localStorage
  const userData = JSON.parse(localStorage.getItem("userData"));
  const userEmail = userData?.email;
  const userMembership = userData?.membership;
  const allowedEmails = [
    "vivek.rane@axzoragroup.com",
    "dikshita.manjrekar@axzoragroup.com",
    "joy@axzoragroup.com",
    "chandu.cheryala@axzorait.com",
  ];
  const allowedMemberships = ["investor_readiness_program"];

  const [showChatApp, setShowChatApp] = useState(false);

  // Update activeLink whenever the route changes
  useEffect(() => {
    setActiveLink(location.pathname);
  }, [location.pathname]);

  // Handler to close ChatApp when a link is clicked
  const handleLinkClick = () => {
    setShowChatApp(false);
  };

  // Handler to open ChatApp
  const handleChatClick = () => {
    setShowChatApp(true);
  };

  // Helper function to determine if any of the paths match the current route
  const isPathActive = (paths) => {
    return paths.some(
      (path) => activeLink === path || activeLink.startsWith(`${path}/`)
    );
  };

  // Helper functions for fill and text colors
  const getFillColor = (paths) => {
    return isPathActive(paths) ? "#66a3a6" : "white";
  };

  const getTextColor = (paths) => {
    return isPathActive(paths) ? "#66a3a6" : "#fff";
  };

  return (
    <div className="sidebar-container">
      <div className="sidebar-icon-container">
        {/* Home Link */}
        <Link to="/home" onClick={handleLinkClick}>
          <div
            className="icon"
            style={{
              backgroundColor: isPathActive(["/home"]) ? "white" : "#66A3A6",
            }}
          >
            <HomeIcon fill={getFillColor(["/home"])} />
            <p
              className={isPathActive(["/home"]) ? "sb-active" : "inactive"}
              style={{ color: getTextColor(["/home"]) }}
            >
              Home
            </p>
          </div>
        </Link>

        {/* Meeting Rooms Link */}
        <Link to="/meeting-rooms" onClick={handleLinkClick}>
          <div
            className="icon"
            style={{
              backgroundColor: isPathActive([
                "/meeting-rooms",
                "/meeting-room-details",
                "/booking-schedule",
              ])
                ? "white"
                : "#66A3A6",
            }}
          >
            <MeetingIcon
              fill={getFillColor([
                "/meeting-rooms",
                "/meeting-room-details",
                "/booking-schedule",
              ])}
            />
            <p
              className={
                isPathActive([
                  "/meeting-rooms",
                  "/meeting-room-details",
                  "/booking-schedule",
                ])
                  ? "sb-active"
                  : "inactive"
              }
              style={{
                color: getTextColor([
                  "/meeting-rooms",
                  "/meeting-room-details",
                  "/booking-schedule",
                ]),
              }}
            >
              Meeting Rooms
            </p>
          </div>
        </Link>

        {/* Coaches Link */}
        {allowedMemberships.includes(userMembership) ? (
          <Link to="/coaches" onClick={handleLinkClick}>
            <div
              className="icon"
              style={{
                backgroundColor: isPathActive([
                  "/coaches",
                  "/coach-details",
                  "/coach-booking-schedule",
                ])
                  ? "white"
                  : "#66A3A6",
              }}
            >
              <CoachesIcon
                fill={getFillColor([
                  "/coaches",
                  "/coach-details",
                  "/coach-booking-schedule",
                ])}
              />
              <p
                className={
                  isPathActive([
                    "/coaches",
                    "/coach-details",
                    "/coach-booking-schedule",
                  ])
                    ? "sb-active"
                    : "inactive"
                }
                style={{
                  color: getTextColor([
                    "/coaches",
                    "/coach-details",
                    "/coach-booking-schedule",
                  ]),
                }}
              >
                Coaches
              </p>
            </div>
          </Link>
        ) : null}

        {/* Policy Link */}
        <Link to="/policy" onClick={handleLinkClick}>
          <div
            className="icon"
            style={{
              backgroundColor: isPathActive(["/policy"]) ? "white" : "#66A3A6",
            }}
          >
            <PolicyIcon fill={getFillColor(["/policy"])} />
            <p
              className={isPathActive(["/policy"]) ? "sb-active" : "inactive"}
              style={{
                color: getTextColor(["/policy"]),
              }}
            >
              Policy
            </p>
          </div>
        </Link>

        {/* Events Link (Conditional) */}
        {allowedEmails.includes(userEmail) && (
          <Link to="/events" onClick={handleLinkClick}>
            <div
              className="icon"
              style={{
                backgroundColor: isPathActive(["/events"])
                  ? "white"
                  : "#66A3A6",
              }}
            >
              <EventIcon fill={getFillColor(["/events"])} />
              <p
                className={isPathActive(["/events"]) ? "sb-active" : "inactive"}
                style={{ color: getTextColor(["/events"]) }}
              >
                Events
              </p>
            </div>
          </Link>
        )}

        {/* Chat Icon */}
        <div
          className="icon"
          style={{
            backgroundColor: "#66A3A6",
          }}
          onClick={handleChatClick}
        >
          <FontAwesomeIcon color="white" icon={faComments} />
          <p className="inactive">Chat</p>
        </div>

        {/* Form Builder Link (Conditional) */}
        {allowedEmails.includes(userEmail) && (
          <Link to="/forms" onClick={handleLinkClick}>
            <div
              className="icon"
              style={{
                backgroundColor: isPathActive(["/forms"]) ? "white" : "#66A3A6",
              }}
            >
              <SurveyIcon fill={getFillColor(["/forms"])} />
              <p
                className={isPathActive(["/forms"]) ? "sb-active" : "inactive"}
                style={{ color: getTextColor(["/forms"]) }}
              >
                Form Builder
              </p>
            </div>
          </Link>
        )}

        {/* Chat Application */}
        {showChatApp && <ChatApp onClose={() => setShowChatApp(false)} />}
      </div>
    </div>
  );
};

export default Sidebar;
