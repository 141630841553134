// RoomCard.js
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation
import { ReactComponent as Arrow } from "../..//assets/svg/Arrow.svg";
import StarRating from "../../widgets/StarRating";
import "./MRoomCard.css";
import { Rating } from "@mui/material";
import StarBorderPurple500Outlined from "@mui/icons-material/StarBorderPurple500Outlined";
import { token } from "../../utils/utilityFunctions";
import { useQuery } from "react-query";
import axios from "axios";

const MRoomCard = ({
  imgSrc,
  roomTitle,
  description,
  linkTo,
  onViewClick,
  roomId,
  setRefreshRating,
  isCoach,
}) => {
  const [currentRating, setCurrentRating] = useState(0);

  const [numberOfReviews, setNumberOfReviews] = useState();

  const getReviews = async () => {
    try {
      const response = await axios.get(
        `${window.env_url}/v1/reviews/room/${roomId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const { data, isLoading } = useQuery({
    queryKey: roomTitle,
    queryFn: getReviews,
    onSuccess: (data) => {
      setNumberOfReviews(data.data.body[0].length);
      console.log("number of reviews", data.data.body[0].length);
    },
  });

  const getRatings = async () => {
    try {
      const response = await fetch(
        `${window.env_url}/v1/rooms/ratings/${roomId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(response);
      const data = await response.json();
      setCurrentRating(data.body[0].ratings[0].avgRating);
      setRefreshRating((prev) => prev + 1);
    } catch (error) {
      console.error(error.message);
    }
  };
  const number = Number(currentRating);

  console.log(roomId, `rating ${number}`);

  useEffect(() => {
    getRatings();
  }, []);

  return (
    <div className="room" id="roomCardResp" style={{ overflow: "unset" }}>
      <img
        src={imgSrc}
        className="room-img"
        alt="Room"
        style={{ objectFit: isCoach ? "contain" : "fill" }}
      />
      <div className="room-rating">
        <Rating
          value={number}
          precision={0.5}
          readOnly
          style={{ fontSize: "1.5rem" }}
          emptyIcon={
            <StarBorderPurple500Outlined
              style={{ color: "#faaf00", fontSize: "1.5rem" }}
            />
          }
        />
      </div>
      <div className="details-and-button">
        <div className="room-details home">
          <div className="numberOfRatings">
            Reviews ({numberOfReviews || 0})
          </div>
          <p>{roomTitle}</p>
          <span id="mvDescription">{description}</span>
        </div>
        <Link to={linkTo}>
          <div className="arrow-container">
            <Arrow onClick={onViewClick} />
          </div>
        </Link>
      </div>
    </div>
  );
};

export default MRoomCard;
