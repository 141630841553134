import { useEffect, useState } from "react";
import Navbar from "../../../components/navbar/Navbar";
import Sidebar from "../../../components/sidebar/Sidebar";
import BookingsTimeline from "../../../widgets/BookingsTimeline";
import "../../home/homeinnerui/BookingSchedule.css";
import MSideBar from "../../../components/sidebar/mobile/MSideBar";
import NotificationWidget from "../../../widgets/NotificationWidget";
import { ReactComponent as ToggleSideBar } from "../../../assets/svg/ToggleSidebar.svg";
import MNavbar from "../../../components/navbar/mobile/MNavbar";
import BookForm from "../../form/BookForm";
import CalendarWidget from "../../../widgets/CalendarWidget/CalendarWidget";

import MakeBookingDialog from "../../../dialogs/make-booking/MakeBookingDialog";
import { useLocation, useParams } from "react-router-dom";
import CoachCalendarWidget from "./CoachCalendarWidget";
import MakeCoachBookingDialog from "../../../dialogs/make-booking/MakeCoachBooking";
import { token } from "../../../utils/utilityFunctions";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import CoachRescheduleBooking from "./CoachRescheduleBooking";

const CoachBookingSchedule = ({}) => {
  const homeActive = false;

  const coachBooking = true;

  const screenWidth = window.innerWidth; //769
  const [width, setWidth] = useState(window.innerWidth);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [bookingStats, setBookingStats] = useState(null);
  const [mobileStats, setMObileStats] = useState(1);
  const [selectedBooking, setSelectedBooking] = useState();
  const [view, setView] = useState("month");

  const location = useLocation();
  const coachName = location?.state?.coachName;
  const { id } = useParams();
  const closeBookMenu = () => {
    setIsMenuOpen(false);
  };

  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  console.log("selectedBooking ", selectedBooking);

  useEffect(() => {
    getBookingStats();
  }, [selectedMonth]);

  const getBookingStats = async () => {
    try {
      const response = await fetch(
        `${window.env_url}/v1/coachbookings/stats/${id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify(selectedMonth),
        }
      );
      //console.log(response);
      const data = await response.json();
      //console.log(data.body[0]);
      setBookingStats(data?.body[0]);
    } catch (error) {
      //console.error(error.message);
    }
  };

  const statsCarousel = (section) => {
    setMObileStats(section);
  };

  //console.log(bookingStats);
  console.log(selectedMonth);

  return (
    <div className="cbs-mr-root">
      {width <= 768 ? (
        <>
          <MNavbar />
          <div className="bs-mob-container">
            {mobileStats === 1 ? (
              <div className={`myHistory bs-mv`}>
                <div className="stats bs-mv">
                  <p>All Bookings</p>
                  <div className="numbers bs-mv">
                    <div className="bs-mv-stats success">
                      <span className="bs-mv number span">
                        {bookingStats?.total_successful}
                      </span>
                      <span className="bs-mv status span">Successful</span>
                    </div>
                    <div className="bs border-div"></div>
                    <div className="bs-mv-stats cancelled">
                      <span className="bs-mv number span">
                        {bookingStats?.total_cancelled}
                      </span>
                      <span className="bs-mv status span">Cancelled</span>
                    </div>
                  </div>
                </div>
              </div>
            ) : (
              <div className={`bs-mv bookings-timeline mobileViewBookingStats`}>
                <div className="allbookings">
                  <p className="book-heading-1">{view} Bookings</p>
                  <div className="book-stats-1">
                    <div className="book-stats-1-1">
                      <span className="stat-number">
                        {bookingStats?.date_range_successful}
                      </span>
                      <span className="sub-script" style={{ color: "#46d939" }}>
                        Successful
                      </span>
                    </div>
                    <div class="bs border-div"></div>
                    <div className="book-stats-1-1">
                      <span className="stat-number">
                        {bookingStats?.date_range_cancelled}
                      </span>
                      <span className="sub-script" style={{ color: "#ed2626" }}>
                        Cancelled
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {screenWidth < 769 && (
              <div className="statsCarousel">
                <div
                  class={
                    mobileStats === 1 ? "activeCarouselDot" : "carouselDot"
                  }
                  onClick={() => {
                    statsCarousel(1);
                  }}
                ></div>
                <div
                  class={
                    mobileStats === 2 ? "activeCarouselDot" : "carouselDot"
                  }
                  onClick={() => {
                    statsCarousel(2);
                  }}
                ></div>
              </div>
            )}
            <div className="bs-mv bookings-timeline"></div>
            <div className="cal-btn-container">
              <CoachCalendarWidget
                coachId={id}
                selectedMonth={selectedMonth}
                setSelectedMonth={setSelectedMonth}
                coachName={coachName}
                setCalendarView={setView}
              />
              <div className="make-booking-btn">
                <button onClick={toggleMenu}>Make A Booking</button>
              </div>
            </div>
            {isMenuOpen && (
              <MakeCoachBookingDialog closeBookMenu={closeBookMenu} />
            )}
          </div>
        </>
      ) : width <= 1024 ? (
        <div className="meeetingrooms-root tablet">
          <Navbar />
          <div className="nav-home-rightsection">
            <Sidebar
            // homeActive={homeActive} meetingActive={meetingActive} coachesActive={coachesActive}
            />
            <div className="overall-section">
              <div className="stats-section">
                <div className="allbookings">
                  <p className="book-heading-1">All Bookings</p>
                  <div className="book-stats-1">
                    <div className="book-stats-1-1">
                      <span className="stat-number">
                        {bookingStats?.total_successful}
                      </span>
                      <span className="sub-script">Successful</span>
                    </div>
                    <div className="book-stats-1-1">
                      <span className="stat-number">
                        {bookingStats?.total_cancelled}
                      </span>
                      <span className="sub-script">Cancelled</span>
                    </div>
                  </div>
                </div>
                <div className="allbookings">
                  <p className="book-heading-1">{view} Bookings</p>
                  <div className="book-stats-1">
                    <div className="book-stats-1-1">
                      <span className="stat-number">
                        {bookingStats?.date_range_successful}
                      </span>
                      <span className="sub-script" style={{ color: "#46d939" }}>
                        Successful
                      </span>
                    </div>
                    <div className="book-stats-1-1">
                      <span className="stat-number">
                        {bookingStats?.date_range_cancelled}
                      </span>
                      <span className="sub-script" style={{ color: "#ed2626" }}>
                        Cancelled
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bookings-timeline">
                <div className="heading-calendar-container">
                  <div className="container reservation-form">
                    <CoachCalendarWidget
                      id={id}
                      selectedMonth={selectedMonth}
                      setSelectedMonth={setSelectedMonth}
                      coachName={coachName}
                    />
                  </div>
                </div>
                <div className="make-booking-btn">
                  <button onClick={toggleMenu}>Make A Booking</button>
                </div>
              </div>
            </div>
          </div>
          {isMenuOpen && (
            <MakeCoachBookingDialog closeBookMenu={closeBookMenu} />
          )}
        </div>
      ) : (
        <>
          <Navbar />
          <div className="mr-container">
            <Sidebar homeActive={homeActive} />
            <div className="overall-section">
              <div className="stats-section">
                <div className="book-stats">
                  <div className="allbookings">
                    <p className="book-heading-1">All Bookings</p>
                    <div className="book-stats-1">
                      <div className="book-stats-1-1">
                        <span className="stat-number">
                          {bookingStats?.total_successful}
                        </span>
                        <span className="sub-script">Successful</span>
                      </div>
                      <div className="book-stats-1-1">
                        <span className="stat-number">
                          {bookingStats?.total_cancelled}
                        </span>
                        <span className="sub-script">Cancelled</span>
                      </div>
                    </div>
                  </div>
                  <div className="weekly-bookings">
                    <p className="book-heading-1">{view} bookings</p>
                    <div className="book-stats-2">
                      <div className="book-stats-2-1">
                        <span className="stat-number">
                          {bookingStats?.date_range_successful}
                        </span>
                        <span className="sub-script-successful">
                          Successful
                        </span>
                      </div>
                      <div className="book-stats-2-2">
                        <span className="stat-number">
                          {bookingStats?.date_range_cancelled}
                        </span>
                        <span className="sub-script-cancelled">Cancelled</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="bookings-timeline">
                <div className="container reservation-form">
                  <CoachCalendarWidget
                    coachName={coachName}
                    id={id}
                    selectedMonth={selectedMonth}
                    setSelectedMonth={setSelectedMonth}
                    calendarView={view}
                    setCalendarView={setView}
                    setSelectedBooking={setSelectedBooking}
                  />
                </div>
              </div>
            </div>
          </div>
          {selectedBooking && (
            <Dialog
              open={true}
              onClose={() => {}}
              aria-describedby="alert-dialog-slide-description"
              className="coach-reschedule-booking"
            >
              <DialogTitle>
                <div
                  className="reschedule-booking-header"
                  onClick={() => setSelectedBooking()}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  X
                </div>
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  <CoachRescheduleBooking selectedBooking={selectedBooking} />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <button>Hello</button>
              </DialogActions>
            </Dialog>
          )}
        </>
      )}
    </div>
  );
};

export default CoachBookingSchedule;
