import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { ReactComponent as Arrow } from "../assets/svg/Arrow.svg";
import { Rating, useMediaQuery } from "@mui/material";
import StarBorderPurple500Outlined from "@mui/icons-material/StarBorderPurple500Outlined";
import { token } from "../utils/utilityFunctions";
import { useQuery } from "react-query";
import axios from "axios";
import "./CoachCard.css";

const CoachCard = ({
  imgSrc,
  coachName,
  description,
  linkTo,
  onViewClick,
  coachId,
  setRefreshRating,
}) => {
  const placeHolderImg = "https://picsum.photos/200/300";

  const [currentRating, setCurrentRating] = useState(0);
  const [numberOfReviews, setNumberOfReviews] = useState(0);

  const getReviews = async () => {
    try {
      const response = await axios.get(
        `${window.env_url}/v1/coachreviews/coach/${coachId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return response;
    } catch (error) {
      console.log("error", error);
    }
  };

  const { data } = useQuery({
    queryKey: coachName,
    queryFn: getReviews,
    onSuccess: (data) => {
      setNumberOfReviews(data.data.body[0].length);
    },
  });

  const getRatings = async () => {
    try {
      const response = await fetch(
        `${window.env_url}/v1/coaches/ratings/${coachId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setCurrentRating(data.body[0].ratings[0].avgRating);
      setRefreshRating((prev) => prev + 1);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    getRatings();
  }, [coachId]);

  let coachClassName;
  const path = window.location.href;
  if (path.includes("/home")) {
    coachClassName = "trmr coach extraClass";
  } else {
    coachClassName = "coach coachCard-tablet coachCard-Mobile extraClass";
  }

  const isMobile = useMediaQuery("(max-width : 768px)");
  const isTablet = useMediaQuery("(min-width: 769px) and (max-width: 1024px)");

  return (
    <div className={coachClassName} style={{ overflow: "scroll !important" }}>
      {/* <div className="hovercolorbubble"></div> */}
      <img
        src={imgSrc || placeHolderImg}
        className="room-card room-img coach-card"
      />
      <div className="room-rating coach-rating">
        <Rating
          value={Number(currentRating)}
          precision={0.5}
          readOnly
          style={{ fontSize: (isTablet || isMobile) ? "1.5rem" : "2rem" }}
          emptyIcon={
            <StarBorderPurple500Outlined
              style={{
                color: "#ff931b",
                fontSize:(isTablet || isMobile) ? "1.5rem" : "2rem",
              }}
            />
          }
        />
      </div>
      <div className="details-and-button">
        <div className="room-details home mv" id="roomCardContent">
          <div className="numberOfRatings">
            Reviews ({numberOfReviews || 0})
          </div>
          <p>{coachName}</p>
          <span>{description}</span>
        </div>
        <Link to={linkTo}>
          <div className="arrow-container">
            <Arrow onClick={onViewClick} />
          </div>
        </Link>
      </div>
    </div>
  );
};

CoachCard.propTypes = {
  imgSrc: PropTypes.string.isRequired,
  coachName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  linkTo: PropTypes.string.isRequired,
  onViewClick: PropTypes.func.isRequired,
  coachId: PropTypes.string.isRequired,
  setRefreshRating: PropTypes.func.isRequired,
};

export default CoachCard;
