import { useEffect, useState } from "react";
import { ReactComponent as GoLeft } from "../../assets/svg/GoLeft.svg";
import { ReactComponent as GoRight } from "../../assets/svg/GoRight.svg";
import CustomerCard from "../../cards/CustomerCard";
import { tr } from "date-fns/locale";
import { useMediaQuery } from "@mui/material";
import "./CustomerWidget.css";
import { truncateDescription } from "../../utils/utilityFunctions";

const CustomerWidget = ({
  roomId,
  coachId,
  coachCustomerData,
  roomCustomerData,
}) => {
  const customerMetaData = window.location.pathname?.includes("coach-details")
    ? coachCustomerData
    : roomCustomerData;
  const [currentFirstCard, setCurrentFirstCard] = useState(0);
  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTablet = useMediaQuery("(min-width: 769px) and (max-width: 1023px)");

  console.log("isMobile", isMobile);
  console.log("isTablet", isTablet);
  // Adjusted left click handler based on device type
  const leftClickHandler = () => {
    if (currentFirstCard > 0) {
      setCurrentFirstCard((prev) => prev - (isMobile ? 1 : isTablet ? 2 : 3));
    }
  };


  // Adjusted right click handler based on device type
  const rightClickHandler = () => {
    const size = customerMetaData?.length;
    if (currentFirstCard < size - (isMobile ? 1 : isTablet ? 2 : 3)) {
      setCurrentFirstCard((prev) => prev + (isMobile ? 1 : isTablet ? 2 : 3));
    }
  };

  const type = window.location.pathname?.includes("coach-details")
    ? "Coach"
    : "Meeting Room";
  const getFileUrl = `${window.env_url}/v1/files/`;
  const [slicedData, setSlicedData] = useState([]);

  useEffect(() => {
    const trimmedData = customerMetaData?.slice(
      currentFirstCard,
      currentFirstCard + (isMobile ? 1 : isTablet ? 2 : 3)
    );
    setSlicedData(trimmedData);
  }, [customerMetaData, currentFirstCard]);

  // Determine if it's a mobile device

  return (
    <div className="third-col">
      {customerMetaData?.length > 0 ? (
        <p>
          <u style={{fontSize: "23px"}}>customer Ratings({customerMetaData?.length || 0})</u>
          <hr id="moduleSeperator"></hr>
          <div className="ratings-content-section">
            <div className="content">
              {currentFirstCard > 0 && (
                <GoLeft id="goLeftCustomer" onClick={leftClickHandler} />
              )}
              <div className="coach-card-container">
                {slicedData?.map((customer) => {
                  let imgPath;
                  const imageRes = customer?.user?.image;
                  if (imageRes?.includes("/avataars.io")) {
                    imgPath = imageRes;
                  } else {
                    imgPath = getFileUrl + imageRes;
                  }

                  return (
                    <CustomerCard
                      key={customer.id}
                      id={customer.id}
                      name={
                        customer?.user?.firstname + customer?.user?.lastname
                      }
                      imgurl={imgPath}
                      description={truncateDescription(
                        customer.description,
                        90
                      )}
                      rating={customer.rating}
                    />
                  );
                })}
              </div>
              {currentFirstCard <
                customerMetaData.length - (isMobile ? 1 : isTablet ? 2 : 3) && (
                <GoRight id="goRightCustomer" onClick={rightClickHandler} />
              )}
            </div>
          </div>
        </p>
      ) : (
        <p>This {type} is yet to be rated.</p>
      )}
    </div>
  );
};

export default CustomerWidget;
