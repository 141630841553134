import { useEffect, useState } from "react";
import "./MakeBookingDialog.css";
import moment from "moment";
import MultiSelect from "../../components/MultiSelect";
import MultiSelectDropdown from "../../components/MultiSelect";
import { db } from "../../widgets/firebase";
import { addDoc, collection } from "firebase/firestore";
import { useParams } from "react-router-dom";
import { token } from "../../utils/utilityFunctions";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

const MakeBookingDialog = ({ closeBookMenu }) => {
  const [selectedFacilties, setSelectedFacilties] = useState([]);
  const [facilityData, setFacilityData] = useState([]);
  const location = useLocation();
  const { state } = location;
  const { roomData } = state;
  const roomSeats = roomData.title.split("|")[1];

  const updateFacilities = (e) => {
    // setSelectedFacilties(e.value);
    const {
      target: { value },
    } = e;
    setSelectedFacilties(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const ref = collection(db, "userrequest");

  const { id } = useParams();

  console.log("Make", id);

  const fetchFacilities = async () => {
    const apiUrl = `${window.env_url}/v1/filterfacilities`;
    const token = localStorage.getItem("accessToken");
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await response.json();
      setFacilityData(data.body[0] || []);
    } catch (error) {
      console.error(error.message);
    }
  };

  //console.log(facilityData);

  const facilities = facilityData.map((data, index) => {
    return {
      name: data.title,
      code: data.id,
    };
  });

  const [events, setEvents] = useState([
    {
      title: "Event 1",
      start: new Date(),
      end: new Date(2024, 2, 1),
    },
  ]);

  const [newEvent, setNewEvent] = useState({
    title: "",
    start: "",
    end: "",
    startTime: "",
    endTime: "",
    facilities: "",
    duration: "",
    notes: "",
  });
  const [eventss, setEventss] = useState(null);

  const [allEvents, setAllEvents] = useState(events);

  const getAllEvents = async () => {
    try {
      const response = await fetch(
        `${window.env_url}/v1/meetingbookings/${id}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setEventss(data.body[0]);
    } catch (error) {
      console.error(error.message);
    }
  };

  const formattedEvents = eventss?.map((event) => {
    const startDateTime = moment(
      `${event.checkInDate} ${event.checkInTime}`,
      "YYYY-MM-DD HH:mm:ss"
    );
    const endDateTime = moment(
      `${event.checkOutDate} ${event.checkOutTime}`,
      "YYYY-MM-DD HH:mm:ss"
    );

    return {
      id: event.id,
      title: "Event", // You can customize this if you have specific titles
      start: startDateTime.toDate(), // Format the start datetime
      end: endDateTime.toDate(), // Format the end datetime
    };
  });

  useEffect(() => {
    fetchFacilities();
    getAllEvents();
  }, []);

  const getFileUrl = `${window.env_url}/v1/files/`;

  const profileImage = sessionStorage.getItem("profPicture");
  let imgPath;
  if (profileImage?.includes("avataaars.io")) {
    imgPath = profileImage;
  } else {
    imgPath = getFileUrl + profileImage;
  }

  const handleAddEvent = () => {
    const startTime = moment(newEvent.startTime, "HH:mm:ss");
    const startTimeMoment = moment(startTime, "HH:mm");
    const endTimeMoment = startTimeMoment
      .clone()
      .add(newEvent.duration, "hours");
    let startDate = moment(newEvent.start);
    let endDate = moment(newEvent.end);
    const roomEvent = {
      // title: "Rando0m",
      checkInDate: moment(startDate).format("YYYY-MM-DD"),
      checkOutDate: moment(startDate).format("YYYY-MM-DD"),
      checkInTime: startTimeMoment.format("HH:mm:ss"),
      checkOutTime: endTimeMoment.format("HH:mm:ss"),
      facilities: selectedFacilties.map((item, i) => item),
      duration: newEvent.duration,
      notes: newEvent.notes,
    };
    const event = roomEvent;
    console.log(event);

    const postRoomBooking = async () => {
      const token = localStorage.getItem("accessToken");
      try {
        const response = await fetch(
          `${window.env_url}/v1/meetingbookings/book/${id}`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(event),
          }
        );
        const data = await response.json();
        console.log("Res", response);
        console.log("data", data);
        if (response.ok) {
          toast.success("Room Booking request made");
          closeBookMenu();
        } else {
          console.log("response", response);
          toast.error("Room Booking failed !");
        }
        if (data) {
          let dataToSend = {
            username: sessionStorage.getItem("fullName"),
            message: "Booked a meeting room",
            duration: data.body[0].duration,
            profileImage: imgPath,
          };
          try {
            await addDoc(ref, dataToSend);
            console.log("Data sent to Firebase successfully", dataToSend);
          } catch (firebaseError) {
            console.log("Error adding data to Firebase:", firebaseError);
          }
        }
      } catch (error) {
        console.error(error.message);
      }
    };

    postRoomBooking();
  };
  return (
    <>
      <div className="bookFormWrapper">
        <div className="bmrf">
          <div className="bmrf-heading">
            <p>MAKE A BOOKING</p>
          </div>
          <div className="bmrf-form">
            <div className="bmrfFormFields">
              <div className="row1">
                <div>
                  <label className="makebold" htmlFor="">
                    Date*
                  </label>
                  <input
                    type="date"
                    name="startDate"
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, start: event.target.value })
                    }
                  />
                </div>
              </div>

              <div className="row1">
                <div>
                  <label className="makebold" htmlFor="">
                    Seats
                  </label>
                  <input
                    type="number"
                    placeholder={`Seating capacity is ${roomSeats}`}
                    name="seats"
                    value={newEvent.seats}
                    onChange={(event) => {
                      setNewEvent({ ...newEvent, seats: event.target.value });
                    }}
                  />
                </div>
              </div>

              <div className="row2">
                <div>
                  <label className="makebold" htmlFor="">
                    Time
                  </label>
                  <input
                    type="time"
                    name="startTime"
                    onChange={(event) =>
                      setNewEvent({
                        ...newEvent,
                        startTime: event.target.value,
                      })
                    }
                  />
                </div>
              </div>

              <div className="row3" style={{ border: "none" }}>
                <div>
                  <label className="makebold" htmlFor="">
                    Duration
                  </label>
                  <input
                    type="number"
                    name="duration"
                    placeholder="Duration"
                    value={newEvent.duration}
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, duration: event.target.value })
                    }
                  />
                </div>
              </div>
              <div className="row4">
                <MultiSelectDropdown
                  value={selectedFacilties}
                  onChangeEvent={updateFacilities}
                  options={facilities}
                  placeholder="Select Facilities"
                />
              </div>
              <div className="row5">
                <div className="row5" style={{ display: "block" }}>
                  <label className="makebold" htmlFor="">
                    Description
                  </label>
                  <textarea
                    type="text"
                    name="notes"
                    value={newEvent.notes}
                    placeholder="Description"
                    onChange={(event) =>
                      setNewEvent({ ...newEvent, notes: event.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="form-sbt-btn">
              <button onClick={handleAddEvent}>Submit</button>
              <button onClick={closeBookMenu}>Close</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MakeBookingDialog;
