import Navbar from "../../components/navbar/Navbar";
import Sidebar from "../../components/sidebar/Sidebar";
import "./Home.css";
import { ReactComponent as CoachColorIcon } from "../../assets/svg/Coach.svg";
import { ReactComponent as MeetingColorIcon } from "../../assets/svg/Meeting.svg";
import { ReactComponent as RatingsColorIcon } from "../../assets/svg/Ratings.svg";
import { ReactComponent as ReviewsColorIcon } from "../../assets/svg/Reviews.svg";
import rightIcon from "../../assets/svg/GoRight.svg";
import leftIcon from "../../assets/svg/GoLeft.svg";
import { ReactComponent as Arrow } from "../../assets/svg/Arrow.svg";
import { ReactComponent as ToggleSideBar } from "../../assets/svg/ToggleSidebar.svg";
import { ReactComponent as MeetingsStats } from "../../assets/svg/MeetingsStatsIcon.svg";
import { Link } from "react-router-dom";
import StarRating from "../../widgets/StarRating";
import { useEffect, useState } from "react";
import NotificationWidget from "../../widgets/NotificationWidget";
import MSideBar from "../../components/sidebar/mobile/MSideBar";
import MNavbar from "../../components/navbar/mobile/MNavbar";
import "../../cards/CoachCard.css";
import "../../cards/NewsCard.css";
import RoomCard from "../../cards/RoomCard";
import CoachCard from "../../cards/CoachCard";
import NewsCard from "../../cards/NewsCard";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {
  extractFirstValue,
  token,
  truncateDescription,
} from "../../utils/utilityFunctions";
import MRoomCard from "../../cards/mobile/MRoomCard";
import { useQueries, useQuery } from "react-query";
import NewsModal from "../../components/NewsModal";
import axios from "axios";
import DummyComponent from "./DummyComponent";
import { useMediaQuery } from "@mui/material";

const Home = () => {
  const homeActive = true;
  const meetingActive = false;
  const coachesActive = false;
  const [selectedSlice, setSelectedSlice] = useState(0);
  const [selectedMobileSlice, setSelectedMobileSlice] = useState(0);
  const [selectedRoomSlice, setSelectedRoomSlice] = useState(0);
  const [selectedCoachSlice, setSelectedCoachSlice] = useState(0);
  const [selectedMRoomSlice, setSelectedMRoomSlice] = useState(0);
  const [selectedMCoachSlice, setSelectedMCoachSlice] = useState(0);
  const [openNewsModal, setOpenNewsModal] = useState({ open: false, data: {} });

  const [currentSlide, setCurrentSlide] = useState({
    slideId: 0,
    newsItem: {},
  });

  const handleOpenNewsModal = (newsData) => {
    setOpenNewsModal({ open: true, data: { ...newsData } });
  };

  const handleCloseNewsModal = () =>
    setOpenNewsModal({ open: false, data: {} });

  const [width, setWidth] = useState(window.innerWidth);
  window.addEventListener("resize", () => {
    setWidth(window.innerWidth);
  });
  const getFileUrl = `${window.env_url}/v1/files/`;
  const [newsData, setNewsData] = useState([]);
  const [statsData, setStatsData] = useState({
    ratings: 0,
    credits: 0,
  });

  const [priorityNewsDialogOpen, setPriorityNewsDialogOpen] = useState(false);
  const [priorityNews, setPriorityNews] = useState([]);
  const [refreshRating, setRefreshRating] = useState(null);

  const [meetingStat, setMeetingStat] = useState(0);
  const [coachStat, setCoachStat] = useState(0);

  const [topRoomsData, setTopRoomsData] = useState([]);

  const [priorityNewsOpen, setPriorityNewsOpen] = useState({
    open: false,
    data: {},
  });

  const [topCardsData, setTopCardsData] = useState([]);
  // const topRatedRoomsCoaches = useQueries([
  //   {
  //     queryKey: ['topRatedRooms', getFileUrl],
  //     queryFn: () => fetchTopRatedMeetingRooms(getFileUrl),
  //     onSuccess: (data) => {
  //       // setTopRoomsData(data);
  //     }
  //   },
  //   {
  //     queryKey: ["Meeting", getFileUrl],
  //     queryFn: () => fetchTopRatedCoaches(getFileUrl),
  //     onSuccess: (data) => {
  //       // setTopCardsData(data);
  //     },
  //   }
  // ]);

  // const isLoading = topRatedRoomsCoaches.some((query) => query.isLoading);

  useEffect(() => {
    const isPriorityNewsDialogOpen =
      sessionStorage.getItem("newsPriorityDialog");
    if (priorityNews?.length > 0 && isPriorityNewsDialogOpen === "1") {
      setPriorityNewsDialogOpen(true);
      setCurrentSlide({
        slideId: 0,
        newsItem: priorityNews[0],
      });
    }
  }, [priorityNews]);

  const fetchNews = async () => {
    const apiUrl = `${window.env_url}/v1/news/`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      const _news = data.body[0];
      const _priorityNews = _news?.filter((__news) => __news?.priority) || [];

      setPriorityNews(_priorityNews || []);
      setNewsData(data.body[0]);
      return data;
    } catch (error) {
      console.error("Error getting news", error);
    }
  };

  const fetchStats = async () => {
    const apiUrl = `${window.env_url}/v1/users/mystats`;
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-type": "application/json",
        },
      });
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setStatsData(data.body[0]);
    } catch (error) {
      console.error("Error getting news", error);
    }
  };

  const fetchMeetingStats = async () => {
    try {
      const response = await fetch(
        `${window.env_url}/v1/meetingbookings/user/stats/`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setMeetingStat(data.body.meetings);
    } catch (error) {
      console.error(error.message);
    }
  };

  const fetchCoachStats = async () => {
    try {
      const response = await fetch(
        `${window.env_url}/v1/coachbookings/user/stats`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const data = await response.json();
      setCoachStat(data.body.coaches);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    fetchNews();
    fetchStats();
    fetchMeetingStats();
    fetchCoachStats();
  }, []);

  let limiter;
  if (width >= 769 && width <= 1023) {
    limiter = 4;
  } else {
    limiter = 3;
  }

  useEffect(() => {
    fetchTopRatedCoaches();
    fetchTopRatedMeetingRooms();
  }, [limiter]);

  useEffect(() => {
    const timer = setInterval(() => {
      setSelectedMobileSlice((prevValue) => {
        if (prevValue === MobileSlideItems.length - 1) {
          return 0;
        }
        return prevValue + 1;
      });
    }, 3000);
    return () => {
      if (timer) {
        clearInterval(timer);
      }
    };
  }, []);

  const fetchTopRatedCoaches = async () => {
    const apiUrl = `${window.env_url}/v1/coaches/toprated`;
    //?limit=${limiter}&offset=0
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP Status Error ${response.status}`);
      }
      const data = await response.json();
      const topratedCoaches = data.body[0];

      const reviewsPromises = [];

      for (let i = 0; i < topratedCoaches.length; i++) {
        reviewsPromises.push(
          axios.get(
            `${window.env_url}/v1/coachreviews/coach/${topratedCoaches[i].id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
        );
      }

      const allreviews = await Promise.all(reviewsPromises);

      for (let i = 0; i < allreviews.length; i++) {
        topratedCoaches[i].reviews = allreviews[i].data.body[0].length;
      }

      setTopCardsData(
        topratedCoaches.sort((a, b) => b.reviews - a.reviews).slice(0, limiter)
      );
      return topratedCoaches;
    } catch (error) {
      console.error(`Error fetching Most relevant Coaches`, error);
    }
  };

  const fetchTopRatedMeetingRooms = async () => {
    const apiUrl = `${window.env_url}/v1/rooms/toprated`;
    //?limit=${limiter}&offset=0
    try {
      const response = await fetch(apiUrl, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!response.ok) {
        throw new Error(`HTTP error! Status : ${response.status}`);
      }
      const data = await response.json();
      const topratedRooms = data.body[0];

      const reviewsPromises = [];

      for (let i = 0; i < topratedRooms.length; i++) {
        reviewsPromises.push(
          axios.get(
            `${window.env_url}/v1/reviews/room/${topratedRooms[i].id}`,
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          )
        );
      }

      const allreviews = await Promise.all(reviewsPromises);

      for (let i = 0; i < allreviews.length; i++) {
        topratedRooms[i].reviews = allreviews[i].data.body[0].length;
      }

      setTopRoomsData(
        topratedRooms.sort((a, b) => b.reviews - a.reviews).slice(0, limiter)
      );
      return data.body[0];
    } catch (error) {
      console.error("Error fetching rooms", error);
    }
  };

  const handleViewRoom = (roomId) => {
    window.location.href = `/meeting-room-details/${roomId}`;
  };

  const handleCoachClick = (coachId) => {
    window.location.href = `coach-details/${coachId}`;
  };

  const credits = statsData?.credits.toString();
  const trimmedCredits = truncateDescription(credits, 10);
  const trimmedTabletCredits = truncateDescription(credits, 2);

  const statItems = [
    {
      id: 0,
      Icon: <MeetingColorIcon />,
      title: "Meeting Rooms",
      ratings: meetingStat,
    },
    {
      id: 1,
      Icon: <CoachColorIcon />,
      title: "Coaches",
      ratings: coachStat,
    },
    {
      id: 2,
      Icon: <RatingsColorIcon />,
      title: "Ratings",
      ratings: statsData?.ratings,
    },
    {
      id: 3,
      Icon: <ReviewsColorIcon />,
      title: "Credits",
      ratings: trimmedTabletCredits,
    },
  ];

  const MobileSlideItems = [
    {
      id: 1,
      title: "Meeting Rooms",
      ratings: meetingStat,
    },
    {
      id: 2,
      title: "Coaches",
      ratings: coachStat,
    },
    {
      id: 3,
      title: "Ratings",
      ratings: statsData?.ratings,
    },
    {
      id: 4,
      title: "Credits",
      ratings: trimmedCredits,
    },
  ];

  //MOBILE ROOM CARD CAROUSEL

  const SlideMRoomItems = [
    {
      id: topRoomsData[0]?.id,
      imgSrc: getFileUrl + topRoomsData[0]?.files,
      ratings: topRoomsData[0]?.rating,
      totalStars: 5,
      roomTitle: extractFirstValue(topRoomsData[0]?.title, "|"),
      roomType: topRoomsData[0]?.roomType,
      description: topRoomsData[0]?.description,
      onViewClick: () => handleViewRoom(topRoomsData[0]?.id),
    },
    {
      id: topRoomsData[1]?.id,
      imgSrc: getFileUrl + topRoomsData[1]?.files,
      ratings: topRoomsData[1]?.rating,
      totalStars: 5,
      roomTitle: extractFirstValue(topRoomsData[1]?.title, "|"),
      roomType: topRoomsData[1]?.roomType,
      description: topRoomsData[1]?.description,
      onViewClick: () => handleViewRoom(topRoomsData[1]?.id),
    },
  ];

  //MOBILE COACH CARD CAROUSEL

  const SlideMCoachItems = [
    {
      id: topCardsData[0]?.id,
      imgSrc: getFileUrl + topCardsData[0]?.files,
      rating: topCardsData[0]?.ratings,
      coachName: topCardsData[0]?.title,
      description: truncateDescription(topCardsData[0]?.description, 120),
      onViewClick: () => handleCoachClick(topCardsData[0]?.id),
    },
    {
      id: topCardsData[1]?.id,
      imgSrc: getFileUrl + topCardsData[1]?.files,
      rating: topCardsData[1]?.ratings,
      coachName: topCardsData[1]?.title,
      description: truncateDescription(topCardsData[1]?.description, 120),
      onViewClick: () => handleCoachClick(topCardsData[1]?.id),
    },
  ];

  //for tablet

  const slideRoomItems = [
    {
      id: 0,
      items: [
        {
          id: topRoomsData[0]?.id,
          imgSrc: getFileUrl + topRoomsData[0]?.files,
          ratings: topRoomsData[0]?.rating,
          totalStars: 5,
          roomTitle: topRoomsData[0]?.title,
          roomType: topRoomsData[0]?.roomType,
          description: topRoomsData[0]?.description,
          onViewClick: () => handleViewRoom(topRoomsData[0]?.id),
        },
        {
          id: topRoomsData[1]?.id,
          imgSrc: getFileUrl + topRoomsData[1]?.files,
          ratings: topRoomsData[1]?.rating,
          totalStars: 5,
          roomTitle: topRoomsData[1]?.title,
          roomType: topRoomsData[1]?.roomType,
          description: topRoomsData[1]?.description,
          onViewClick: () => handleViewRoom(topRoomsData[1]?.id),
        },
      ],
    },
    {
      id: 1,
      items: [
        {
          id: topRoomsData[3]?.id,
          imgSrc: getFileUrl + topRoomsData[2]?.files,
          ratings: topRoomsData[2]?.rating,
          totalStars: 5,
          roomTitle: topRoomsData[2]?.title,
          roomType: topRoomsData[2]?.roomType,
          description: topRoomsData[2]?.description,
          onViewClick: () => handleViewRoom(topRoomsData[2]?.id),
        },
        {
          id: topRoomsData[4]?.id,
          imgSrc: getFileUrl + topRoomsData[3]?.files,
          ratings: topRoomsData[3]?.rating,
          totalStars: 5,
          roomTitle: topRoomsData[3]?.title,
          roomType: topRoomsData[3]?.roomType,
          description: topRoomsData[3]?.description,
          onViewClick: () => handleViewRoom(topRoomsData[3]?.id),
        },
      ],
    },
  ];

  const slideCoachItems = [
    {
      id: 0,
      items: [
        {
          id: topCardsData[0]?.id,
          imgSrc: getFileUrl + topCardsData[0]?.files,
          rating: topCardsData[0]?.ratings,
          coachName: topCardsData[0]?.title,
          description: topCardsData[0]?.description,
          onViewClick: () => handleCoachClick(topCardsData[0]?.id),
        },
        {
          id: topCardsData[1]?.id,
          imgSrc: getFileUrl + topCardsData[1]?.files,
          rating: topCardsData[1]?.ratings,
          coachName: topCardsData[1]?.title,
          description: topCardsData[1]?.description,
          onViewClick: () => handleCoachClick(topCardsData[1]?.id),
        },
      ],
    },
    {
      id: 1,
      items: [
        {
          id: topCardsData[2]?.id,
          imgSrc: getFileUrl + topCardsData[2]?.files,
          rating: topCardsData[2]?.ratings,
          coachName: topCardsData[2]?.title,
          description: topCardsData[2]?.description,
          onViewClick: () => handleCoachClick(topCardsData[2]?.id),
        },
        {
          id: topCardsData[3]?.id,
          imgSrc: getFileUrl + topCardsData[3]?.files,
          rating: topCardsData[3]?.ratings,
          coachName: topCardsData[3]?.title,
          description: topCardsData[3]?.description,
          onViewClick: () => handleCoachClick(topCardsData[3]?.id),
        },
      ],
    },
  ];

  const modalStyles = {
    "& .MuiDialog-paper": {
      outline: "none",
      boxShadow: "none",
    },
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 890,
    height: 666,
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "0 32px 32px 32px",
    borderRadius: 8,
  };

  const tabletStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "82vw",
    height: "68vw",
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "0 32px 32px 32px",
    borderRadius: 2,
  };

  const mobileStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 890,
    height: 666,
    bgcolor: "background.paper",
    boxShadow: 24,
    padding: "0 32px 32px 32px",
    borderRadius: 8,
  };

  const isDesktop = useMediaQuery("(min-width: 1024px)");
  const isTablet = useMediaQuery("(min-width: 769px) and (max-width: 1023px)");
  const isMobile = useMediaQuery("(max-width: 768px)");

  const priorityNewsHandler = (__news) => {
    setPriorityNewsOpen({
      open: true,
      data: {
        newsDescription: __news?.description,
        newsTitle: __news?.title,
        imgSrc: `${process.env.REACT_APP_API_URL}/v1/files/` + __news?.files,
      },
    });
  };

  const handlePriorityClose = () => {
    setPriorityNewsOpen();
  };

  const closeNewsPriorityDialog = () => {
    sessionStorage.setItem("newsPriorityDialog", 0);
    setPriorityNewsDialogOpen(false);
  };

  const priorityNewsLeftHandler = () => {
    if (currentSlide?.slideId - 1 >= 0) {
      setCurrentSlide({
        slideId: currentSlide?.slideId - 1,
        newsItem: priorityNews[currentSlide?.slideId - 1],
      });
    }
  };

  const priorityNewsRightHandler = () => {
    if (currentSlide?.slideId + 1 <= priorityNews?.length - 1) {
      setCurrentSlide({
        slideId: currentSlide?.slideId + 1,
        newsItem: priorityNews[currentSlide?.slideId + 1],
      });
    }
  };

  return (
    <>
      <div className="home-root tablet">
        {width <= 768 ? (
          <>
            <MNavbar />
            <div className="user-stats-container-carousel">
              {MobileSlideItems?.map((stats, index) => {
                return (
                  <div key={index} className="user-stats-container">
                    <div className="stats-rating-container">
                      <span>{stats?.ratings}</span>
                    </div>
                    <div className="stats-title">{stats?.title}</div>
                  </div>
                );
              })}
            </div>
            <div className="meetingRoomSection">
              <span className="sectionHeading">
                Most Relevant Meeting Rooms
              </span>
              {topRoomsData.length > 0 && (
                <div className="mobStats-carousel">
                  <div className="mobStats">
                    {SlideMRoomItems.map((itemData, index) => (
                      <MRoomCard
                        key={itemData.id}
                        roomId={itemData.id}
                        imgSrc={itemData.imgSrc}
                        ratings={itemData.ratings}
                        roomTitle={itemData.roomTitle}
                        roomType={itemData.roomType}
                        description={itemData.description}
                        onViewClick={itemData.onViewClick}
                        isCoach={false}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
            <div className="coach-info home" id="coachInfoResp">
              <p>Most Relevant Coaches</p>
              {topCardsData.length > 0 && (
                <div className="mobStats-carousel">
                  <div className="mobStats">
                    {SlideMCoachItems?.map((itemData, index) => (
                      <MRoomCard
                        key={itemData.id}
                        roomId={itemData.id}
                        imgSrc={itemData.imgSrc}
                        ratings={itemData.ratings}
                        roomTitle={itemData.roomTitle}
                        roomType={itemData.roomType}
                        description={itemData.description}
                        onViewClick={itemData.onViewClick}
                        isCoach={true}
                      />
                    ))}
                  </div>
                </div>
              )}
            </div>
          </>
        ) : width < 1024 ? (
          <>
            {openNewsModal?.open && (
              <NewsModal
                handleClose={handleCloseNewsModal}
                handleOpen={handleOpenNewsModal}
                open={openNewsModal}
              />
            )}
            {/* <Navbar /> */}
            <Navbar />
            <div className="nav-home-rightsection">
              <Sidebar
                homeActive={homeActive}
                meetingActive={meetingActive}
                coachesActive={coachesActive}
              />
              <div className="home-container tablet-home">
                <div className="home-right-section home tablet">
                  <div className="statistics home">
                    <div className="tabStats-carousel">
                      <div className="tabStats">
                        {statItems.map((itemData, index) => {
                          const { Icon } = itemData;
                          return (
                            <div className="tabStat" key={index}>
                              <div className="icon-container tablet-stats">
                                <p className="stat-desc-ratings">
                                  {itemData.ratings}
                                </p>
                              </div>
                              <div className="stats-info">
                                <p className="stat-desc">{itemData?.title}</p>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="tr rooms-and-updates">
                    <div className="trmr room-info">
                      <p>Most relevant Meeting Rooms</p>
                      {topCardsData.length > 0 && (
                        <div className="trmr room-cards">
                          <div className="room-card-carousel">
                            {slideRoomItems[selectedRoomSlice].items.map(
                              (cardData, index) => {
                                if (cardData?.id) {
                                  return (
                                    <RoomCard
                                      key={cardData?.id}
                                      imgSrc={extractFirstValue(
                                        cardData.imgSrc,
                                        "|"
                                      )}
                                      roomId={cardData?.id}
                                      totalStars={5}
                                      roomTitle={extractFirstValue(
                                        cardData.roomTitle,
                                        "|"
                                      )}
                                      roomType={cardData.roomType}
                                      description={truncateDescription(
                                        cardData.description,
                                        90
                                      )}
                                      onViewClick={cardData.onViewClick}
                                    />
                                  );
                                }
                              }
                            )}
                          </div>
                          <div className="carousel-container">
                            {slideRoomItems.map((_, index) => (
                              <div
                                className={`carousel-dot  ${
                                  index === selectedRoomSlice &&
                                  "active-carousel-dot"
                                }`}
                                onClick={() => setSelectedRoomSlice(index)}
                                key={index}
                              ></div>
                            ))}
                          </div>
                        </div>
                      )}
                      <div className="coach-info home">
                        <p>Most relevant Coaches</p>
                        {topCardsData.length > 0 && (
                          <div className="coach-card-carousel">
                            <div className="trc coach-card-container">
                              {slideCoachItems[selectedCoachSlice].items.map(
                                (coachData, index) => (
                                  <CoachCard
                                    key={coachData?.id}
                                    imgSrc={coachData.imgSrc}
                                    coachId={coachData?.id}
                                    rating={coachData.rating}
                                    coachName={coachData.coachName}
                                    description={truncateDescription(
                                      coachData.description,
                                      80
                                    )}
                                    onViewClick={coachData.onViewClick}
                                  />
                                )
                              )}
                            </div>
                            <div className="carousel-container">
                              {slideCoachItems.map((_, index) => (
                                <div
                                  className={`carousel-dot  ${
                                    index === selectedCoachSlice &&
                                    "active-carousel-dot"
                                  }`}
                                  onClick={() => setSelectedCoachSlice(index)}
                                  key={index}
                                ></div>
                              ))}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="tr-updates">
                    <div className="news-and-updates home">
                      <div className="heading">
                        <div className="header">
                          <p className="header-heading">Latest News/Updates</p>
                        </div>
                        <hr />
                        <div className="news-container">
                          {newsData.map((data, index) => (
                            <NewsCard
                              onClick={handleOpenNewsModal}
                              key={index}
                              newsTitle={data.title}
                              imgSrc={getFileUrl + data.files}
                              newsDescription={data.description}
                              description={truncateDescription(
                                data.description,
                                120
                              )}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {openNewsModal?.open && (
              <NewsModal
                handleClose={handleCloseNewsModal}
                handleOpen={handleOpenNewsModal}
                open={openNewsModal}
              />
            )}
            <Navbar />
            <div className="home-container">
              <Sidebar
                homeActive={homeActive}
                meetingActive={meetingActive}
                coachesActive={coachesActive}
              />
              <div className="home-right-section home">
                <div className="tr rooms-and-stats">
                  <div className="statistics home">
                    <div className="stats">
                      <DummyComponent
                        figure={meetingStat}
                        description={"Meeting Rooms"}
                      />
                      <DummyComponent
                        figure={coachStat}
                        description={"Coaches"}
                      />
                      <DummyComponent
                        figure={statsData?.ratings}
                        description={"Ratings"}
                      />
                      <DummyComponent
                        figure={statsData.credits}
                        description={"Credits"}
                      />
                    </div>
                  </div>
                  <div className="trmr room-info">
                    <p>Most relevant Meeting Rooms</p>
                    <div className="trmr room-cards">
                      {topRoomsData.length === 0 ? (
                        <p>There are currently no meeting rooms to display.</p>
                      ) : (
                        <>
                          {topRoomsData.map((cardData, index) => (
                            <RoomCard
                              key={cardData?.id}
                              roomId={cardData?.id}
                              imgSrc={
                                getFileUrl +
                                extractFirstValue(cardData?.files, "|")
                              }
                              roomTitle={extractFirstValue(
                                cardData?.title,
                                "|"
                              )}
                              roomType={cardData.roomType}
                              description={truncateDescription(
                                cardData.description,
                                60
                              )}
                              onViewClick={() => handleViewRoom(cardData.id)}
                              displayReviews={true}
                            />
                          ))}
                        </>
                      )}
                    </div>
                    <div className="coach-info home">
                      <p>Most relevant Coaches</p>
                      <div className="trmr room-cards">
                        {topCardsData.length === 0 ? (
                          <p>There are currently no coaches to display.</p>
                        ) : (
                          <>
                            {topCardsData.map((cardData, index) => (
                              <CoachCard
                                key={cardData?.id}
                                coachId={cardData?.id}
                                imgSrc={getFileUrl + cardData.files}
                                coachName={cardData.title}
                                description={truncateDescription(
                                  cardData.description,
                                  60
                                )}
                                onViewClick={() =>
                                  handleCoachClick(cardData.id)
                                }
                              />
                              // <RoomCard
                              //   key={cardData?.id}
                              //   roomId={cardData?.id}
                              //   imgSrc={
                              //     getFileUrl +
                              //     extractFirstValue(cardData?.files, "|")
                              //   }
                              //   roomTitle={extractFirstValue(
                              //     cardData?.title,
                              //     "|"
                              //   )}
                              //   roomType={cardData.roomType}
                              //   description={truncateDescription(
                              //     cardData.description,
                              //     60
                              //   )}
                              //   onViewClick={() => handleCoachClick(cardData.id)}
                              //   displayReviews={true}
                              // />
                            ))}
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tr-updates">
                  <div className="news-and-updates home">
                    <div className="heading">
                      <p style={{ fontWeight: "bold" }}>Latest News/Updates</p>
                      <hr />
                      <div className="news-container">
                        {newsData.map((data, index) => (
                          <NewsCard
                            onClick={handleOpenNewsModal}
                            key={index}
                            newsTitle={data.title}
                            imgSrc={getFileUrl + data.files}
                            newsDescription={data.description}
                            description={truncateDescription(
                              data.description,
                              120
                            )}
                          />
                        ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
      <Modal
        open={priorityNewsDialogOpen}
        aria-labelledby="modal-modal-title"
        className="priority_news_dialog"
        aria-describedby="modal-modal-description"
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: "rgba(0, 0, 0, 0.5)", // Optional: adjust backdrop styling
            },
          },
          paper: {
            sx: modalStyles,
          },
        }}
      >
        <Box sx={isDesktop ? style : isTablet ? tabletStyle : mobileStyle}>
          <div className="newsContainer">
            <div>
              <h1
                style={{
                  fontFamily: "Nunito",
                  fontSize: isTablet ? "3vw" : "",
                }}
              >
                <span
                  style={{ position: "absolute", right: "6%" }}
                  onClick={closeNewsPriorityDialog}
                >
                  X
                </span>
              </h1>
            </div>

            <div className="priority_news_dialog_news_container">
              <div>
                {currentSlide?.slideId > 0 && (
                  <img
                    className="priority-news-carouse-icon"
                    src={leftIcon}
                    onClick={priorityNewsLeftHandler}
                  />
                )}
              </div>
              {Object.keys(currentSlide?.newsItem).length > 0 && (
                <div
                  className="priority_news_carousel_container"
                  key={currentSlide?.newsItem?.id}
                >
                  <div className="priority_news_title">
                    {currentSlide?.newsItem?.title}
                  </div>

                  {currentSlide?.newsItem?.files && (
                    <div className="priority_news_image_container">
                      <img
                        src={`${process.env.REACT_APP_API_URL}/v1/files/${currentSlide?.newsItem?.files}`}
                        alt={currentSlide?.newsItem?.title || "news image"}
                      />
                    </div>
                  )}

                  <div className="priority_news_description">
                    {currentSlide?.newsItem?.description}
                  </div>
                </div>
              )}
              <div>
                {currentSlide?.slideId < priorityNews?.length - 1 && (
                  <img
                    className="priority-news-carouse-icon"
                    src={rightIcon}
                    onClick={priorityNewsRightHandler}
                  />
                )}
              </div>
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
};

export default Home;
