import { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import "./Authentication.css";
import { toast } from "react-toastify";
import Cookies from "js-cookie";
import { useAuth } from "../../context/AuthContext";
import CustomCloseIcon from "../../assets/svg/CustomCloseIcon";

const Authentication = ({ open, handleResendOtp, onClose }) => {
  const { authData, setAuthData } = useAuth();
  const [otp, setOtp] = useState(new Array(6).fill(""));
  const signUpToken = sessionStorage.getItem("signUpToken");
  const apiUrl = process.env.SERVER_API_URL;

  const [timer, setTimer] = useState(0);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  useEffect(() => {
    if (timer > 0) {
      const countdown = setTimeout(() => setTimer(timer - 1), 1000);
      return () => clearTimeout(countdown);
    } else {
      setIsResendDisabled(false);
    }
  }, [timer]);

  useEffect(() => {
    setAuthData({
      ...authData,
      otp: otp.join(""),
    });
  }, [otp, setAuthData]);

  const handleChange = (element, index) => {
    const value = element.value;

    if (value === "") {
      setOtp([...otp.map((d, idx) => (idx === index ? "" : d))]);
      if (element.previousSibling) {
        element.previousSibling.focus();
      }
    } else if (!isNaN(value) && /^[0-9]$/.test(value)) {
      setOtp([...otp.map((d, idx) => (idx === index ? value : d))]);
      if (element.nextSibling) {
        element.nextSibling.focus();
      }
    }
  };

  const handleResendClick = () => {
    if (isResendDisabled) return;
    handleResendOtp(); // Call the form submit from UserLoginDialog
    setIsResendDisabled(true);
    setTimer(59); // Reset the timer
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    try {
      const response = await fetch(`${window.env_url}/v1/auth/login`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${signUpToken}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(authData),
      });

      if (response.ok) {
        const data = await response.json();
        const accessToken = data.body[0].token;
        const user = data.body[0].user;
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("userData", JSON.stringify(user));
        Cookies.set("access_token", accessToken, {
          expires: 3,
          domain: ".axzoragroup.com",
          path: "/",
          sameSite: "None",
          secure: true,
        });
        window.location.href = "/home";
      } else {
        toast.warn("Invalid OTP. Please try again.");
      }
    } catch (error) {
      console.error("Network error:", error);
    }
  };

  return (
    <Modal
      open={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <div className="box">
        <div className="auth-dialog-content">
          <p className="auth-header">Enter Passkey
          </p>
          <CustomCloseIcon fill="white" onClick={onClose}/>

          <div className="otp-fields">
            <form>
              <div className="auth-form-group">
                {otp.map((data, index) => {
                  return (
                    <input
                      className="code"
                      type="text"
                      name="otp"
                      maxLength="1"
                      key={index}
                      value={data}
                      onChange={(e) => handleChange(e.target, index)}
                      onFocus={(e) => e.target.select()}
                    />
                  );
                })}
              </div>
            </form>
            <div className="verify-button">
              <button onClick={handleFormSubmit}>Verify Passkey</button>
              <p style={{display: "flex", justifyContent: "center"}}>
              {isResendDisabled ? (
                  <span style={{ color: "#999", textAlign: "center", width: "89%" }}>Passkey successfully sent! Request another in {timer}s</span>
                ) : (
                  <span
                    style={{
                      color: "#66a3a6",
                      fontWeight: "700",
                      cursor: "pointer",
                      fontSize: "13px"
                    }}
                    onClick={handleResendClick}
                  >
                    Resend Passkey
                  </span>
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default Authentication;
